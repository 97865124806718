import { Box } from "@material-ui/core";
import React from "react";

import { InfoCard } from "~/components/InfoCard";
import ProductCta from ".";

import { ICTAComponentProps } from "./types";

export const ProductCtaCard = ({ topic, variant, style }: ICTAComponentProps) => {
    return (
        <div style={style || {}}>
            <InfoCard>
                <Box
                    display="flex"
                    flexDirection="column"
                    flexWrap="nowrap"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <ProductCta topic={topic} variant={variant} />
                </Box>
            </InfoCard>
        </div>
    );
};
