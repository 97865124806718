import React, { useEffect } from "react";

import { navigate } from "@utils/navigate";
import { createDriveView, ViewComponent } from "@drive/view";

import { useAuth } from "@api/auth";

import { isProductEnabled } from "@utils/products";

import { ProductType } from "@api/products";
import { ProductCtaCard } from "~/components/ProductCta/Card";

const Drive: ViewComponent = (props) => {
    const { selectedCompany } = useAuth();
    const isDriveToolEnabled = isProductEnabled(selectedCompany, ProductType.DriveTool);

    useEffect(() => {
        if (!isDriveToolEnabled) {
            return;
        }

        navigate("/drive/upcoming", {
            replace: true,
        });
    }, [isDriveToolEnabled]);

    return <ProductCtaCard style={{ marginLeft: 20, marginRight: 20 }} topic="DRIVE_TOOL" />;
};

export default createDriveView(Drive, {
    title: "Test Drive",
});